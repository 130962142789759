<template>
	<div class="grid">
		<div class="col-12">
            <div style="margin-bottom:10px;">
                <a href="/cadastroprodutos"><Button label="Adicionar Produto" class="p-button-primary btn_success" /></a> 
                <Button label="Importar Produto" class="p-button-primary btn_cancel"  @click="modalImportarProdutos()"/>
            </div>
			<div class="card">
                <div class="grid">
                    <div class="col-9">
                        <h5 class="titulo_pedidos">Produtos</h5>
                    </div>
                    <div class="col-3" style="text-align:right">
                        <Dropdown v-model="quantidadePorPagina" :options="quantidades" placeholder="Linhas" @change="setQuantidadePorPagina(quantidadePorPagina)" />
                    </div>
                </div>
                <Loading v-if="loading"/>
                <table class="p-datatable-table" role="rowgroup" v-if="!loading">
                    <thead class="p-datatable-thead" role="rowgroup" >
                        <tr role="row">
                            <th :style="'width:'+nomeColuna['width']" v-for="(nomeColuna, index) in nomeColunas" :key="index" class="p-sortable-column" tabindex="0" role="cell" >
                                <div class="p-column-header-content">
                                    <span v-html="nomeColuna['nome']" class="p-column-title"></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="" role="rowgroup">
                        <tr v-for="(produto, index) in produtos" :key="index" class="" role="row" >
                            <td  v-for="(nomeColuna, index) in nomeColunas" :key="index" role="cell" :style="'width:' + nomeColuna['width']">
                                <div v-if="nomeColuna.value == 'imagem'">
                                    <img :src="produto['imagem']" class="img" />
                                </div>
                                <div v-else-if="nomeColuna.value == 'integracoes'" >
                                    <div v-for="(anuncio, index) in produto.anuncios" :key="index" >
                                        <img :src="anuncio.imagem_marketplace" class="img_marketplace" style="float:left; margin-right:5px;"/>
                                    </div>
                                </div>
                                <div v-else-if="nomeColuna.value =='acoes'">
                                    <button class="p-button p-component p-button-icon-only p-button-text" @click="carregarProduto(produto.id)">
                                        <i class="pi pi-search p-button-icon"></i>
                                    </button>
                                    <button style="color: red;" class="p-button p-component p-button-icon-only p-button-text" @click="excluirProduto(produto.id, produto.anuncios.length)">
                                        <i class="pi pi-times p-button-icon"></i>
                                    </button>
                                </div>
                                <div v-else>
                                    {{ produto[nomeColuna.value] }} 
                                </div> 
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
                <div v-if="quantidadePaginas > 1" class="p-paginator p-component p-paginator-bottom" style="width:100%">
                        <div 
                        style="padding: 5px;cursor: pointer;" @click="primeiraPagina()"> Primeira </div><div style="padding: 5px;cursor: pointer;" @click="anteriorPagina()">Anterior</div>
                        <div style="padding: 5px;cursor: pointer;" v-for="pagina in quantidadePaginas" :key="pagina" @click="paginaAtual(pagina)">{{ pagina }}</div>
                        <div style="padding: 5px;cursor: pointer;" @click="proximaPagina()">Próxima</div>
                        <div style="padding: 5px;cursor: pointer;" @click="ultimaPagina()">Ultima</div>
                    </div>
			</div>
		</div>
	</div>

    <Dialog header="Importar Produtos" v-model:visible="displayImportarCadastrar" style="width: 30rem">
        <div class="div_botoes_importar_produtos" v-if="tipoImportacao == ''">
            <button class="btnImportarViaPlanilha" @click="alterarTipoImportacao('PLANILHA')">Importar via Planilha</button>
            <button class="btnImportarViaAnuncio" @click="alterarTipoImportacao('ANUNCIO')">Importar de Anúncios</button>
        </div>
        
        <div class="flex flex-column">
            <div v-if="tipoImportacao == 'PLANILHA'">
                <div class="grid">
                    <div class="col-12">
                        <h4>Selecione a planilha</h4>
                        <input type="file"
                            id="avatar" name="avatar"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            @change="carregarArquivoPlanilha">
                    </div>
                </div>
                <div style="text-align:center; width:100%; margin-top:10px;">
                    <Button label="Continuar" class="p-button-primary btn_success" @click="importarProduto('PLANILHA')"/> 
                    <Button label="Cancelar" class="p-button-primary btn_cancel" @click="cancelarImportacao()"/> 
                </div>
            </div>
            <div v-if="tipoImportacao == 'ANUNCIO'">
                <div class="grid">
                    <div class="col-12">
                        <h4>Quantidade de produtos</h4>
                        <select v-model="tipo_importacao_marketplace">
                            <option value='UM_PRODUTO'>Um Produto</option>
                            <option value='VARIOS_PRODUTOS'>Vários Produtos</option>
                        </select>
                    </div>

                    <div class="col-12">
                        <h4>Marketplace</h4>
                        <select @change="carregarIntegracaoSelecionada($event.target.selectedOptions[0].value,$event.target.selectedOptions[0].textContent)" v-model="integracao_id">
                            <option v-for="(integracao, index) in integracoes" :key="index" :value="integracao.id">{{integracao.nome}}</option>
                        </select>
                    </div>
                </div>
                <div class="grid" v-if="tipo_importacao_marketplace=='UM_PRODUTO'">
                    <div class="col-12">
                        <h4>SKU do Produto</h4>
                        <InputText placeholder="" v-model="sku_produto_importar" class="largura_total" />
                    </div>
                </div>
                <div style="text-align:center; width:100%; margin-top:10px;">
                    <Button label="Continuar" class="p-button-primary btn_success" @click="importarProduto('ANUNCIO')"/> 
                    <Button label="Cancelar" class="p-button-primary btn_cancel" @click="cancelarImportacao()"/> 
                </div>
            </div>
        </div>
    </Dialog>
    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';

	export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa : null,
            token:"",
            api: "",
            loading: true,
            produtos: null,
            empresa_id:0,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            totalLinhas: null,
            displayImportarCadastrar:false,
            integracao_id:0,
            nome_marketplace_selecionado:'',
            tiposImportacao: [
                {nome: 'Importar Via Planilha', code: 'PLANILHA'},
                {nome: 'Importar Via Dados', code: 'DADOS'},
            ],
            arquivo_planilha:'',
            integracoes:[],
            tipoImportacao: '',
            tipo_importacao_marketplace:'',
            quantidades: ["10", "20", "50"],
            nomeColunas: [
                { nome: "Imagem", value: "imagem", width: "4%" },
                { nome: "sku", value: "sku", width: "9%" },
                { nome: "Nome", value: "nome",width:"38%" },
                { nome: "Preço", value: "preco_normal", width: "9%" },
                { nome: "Estoque", value: "estoque",width:"5%" },
                { nome: "Integrações", value: "integracoes", width: "15%" },
                { nome: "Status", value: "status", width: "4%" },
                { nome: "Ação", value: "acoes", width: "10%" },
            ],
            produtosAdicionar: [
                { nome: "" },
                { descricao: "" },
                { sku: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
            ],
            mostrarAdicionar: false,
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
        this.api.qtdProdutos(this.token,this.empresa_id).then(data => { this.totalLinhas = data.data.quantidade 
        }).catch((error) => {
            if(error.response.status == 401){
                alert(this.msg_fim_sessao);
                this.$router.push('/login');	
            }else{
                alert(this.msg_erro);
            }
        });

        
    },

    mounted() {
        this.listarProdutos();
        
    },  
    methods: {
        carregarIntegracaoSelecionada(integracao_id, nome_marketplace_selecionado){
            this.integracao_id = integracao_id
            this.nome_marketplace_selecionado = nome_marketplace_selecionado
        },
        modalImportarProdutos(){
            this.tipoImportacao = ''
            this.listarIntegracoes();
            this.displayImportarCadastrar = true
        },
        alterarTipoImportacao(tipo){
            if(tipo == "PLANILHA"){
                this.tipoImportacao = 'PLANILHA'
            }else{  
                this.tipoImportacao = 'ANUNCIO'
            }
        },
        importarProduto(){
            if(this.tipoImportacao == "PLANILHA"){
                this.importarProdutosViaPlanilha();
            }else{
                this.importarProdutosViaAnuncio();
            }
        },
        cancelarImportacao(){
            this.tipoImportacao = ''
        },
        listarProdutos(pagina = this.pagina, quantidadePorPagina = this.quantidadePorPagina, ordenar = this.ordenar, tipoOrdenar = this.tipoOrdenar) {
            this.loading = true;
            this.api.listarProdutos(this.token, pagina, quantidadePorPagina, ordenar, tipoOrdenar,this.empresa_id).then(data => {
                this.produtos = data.data.resposta
                this.quantidadePaginas = parseInt(this.totalLinhas) / parseInt(this.quantidadePorPagina)
                this.quantidadePaginas = Math.ceil(this.quantidadePaginas)
                this.loading = false
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        listarIntegracoes() {
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data => {
                this.integracoes = data.data
                this.loading = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        carregarProduto(produto_id){
            this.$router.push({ name: 'produto', params: { id: produto_id } })
        },
        excluirProduto(produto_id, qtd_anuncios) {
            if (qtd_anuncios == 0) {
                if (confirm("Deseja apagar este Produto?")) {
                    this.api.excluirProduto(this.token, produto_id, this.empresa_id).then(data => {
                        if (data.data.verifica_erro == false) {
                            this.listarProdutos()
                        } else {
                            alert(this.msg_erro);
                        }
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            alert(this.msg_fim_sessao);
                            this.$router.push('/login');
                        } else {
                            alert(this.msg_erro);
                        }
                    });
                }
            } else {
                alert('Este produto não pode ser apagado pois contem anuncios vinculados.');
            }
        },
        setQuantidadePorPagina(quantidade) {
            this.quantidadePorPagina = quantidade;
            this.listarProdutos();
        },
        paginaAtual(pagina) {
            this.pagina = pagina;
            // this.primeiroItemDaPagina = pagina * this.quantidadePorPagina + 1 - this.quantidadePorPagina;
            this.listarProdutos();
        },
        proximaPagina() {
            if (this.quantidadePaginas > this.pagina)
                this.paginaAtual(this.pagina + 1);
                this.listarProdutos();
        },
        anteriorPagina() {
            if (this.pagina > 1) {
                this.paginaAtual(this.pagina - 1);
                this.listarProdutos();
            }
        },
        primeiraPagina() {
            this.paginaAtual(1);
            this.listarProdutos();
        },
        ultimaPagina() {
            this.paginaAtual(this.quantidadePaginas);
            this.listarProdutos();
        },
        adicionarProduto() {
            this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Produto Adicionado", life: 3000 });
            this.limparFormProduto();
        },
        carregarArquivoPlanilha(event){
            this.arquivo_planilha = event.target.files[0]
        },
        importarProdutosViaPlanilha(){
            var formData = new FormData();
            formData.append("arquivo",this.arquivo_planilha);
            console.log(formData)
            this.api.importarProdutosPlanilha(this.token, this.empresa_id,formData).then(data => {
                if(data.data.verifica_erro == false){
                    this.fecharModalImportar()
                    this.listarProdutos();
                    
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        importarProdutosViaAnuncio(){
            this.api.importarProdutosAnuncios(this.token, this.empresa_id,this.tipo_importacao_marketplace, this.nome_marketplace_selecionado, this.integracao_id, this.sku_produto_importar).then(data => {
                if (data.data.verifica_erro == false) {
                    this.fecharModalImportar()
                    this.listarProdutos();
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        fecharModalImportar() {
            this.displayImportarCadastrar = false;
        }
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
table{
    width: 100%;
}
.div_botoes_importar_produtos{
    padding:50px;
}
.btnImportarViaPlanilha{
    background-color: #4B89EE;
    padding:10px 15px;
    border-radius:10px;
    color:#fff;
    border:none;
    font-weight:600; 
    width:100%;
    cursor: pointer;
}
.btnImportarViaAnuncio{
    background-color: #8EC253;
    padding:10px 15px;
    border-radius:10px;
    color:#fff;
    border:none;
    font-weight:600;
    width:100%;
    margin-top: 10px;
    cursor: pointer;
}

.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.img{
    width:40px;
}
.img_marketplace{
    border-radius: 50%;
    width:30px;
    height:30px;
    border: #ccc solid 1px;
    padding:5px;
}
.dropdown{
    padding:0;
}
thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
    width:10%
}
.inputtext{
    width: 100%;
}
.btn_success{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.btn_success:hover{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}

.btn_cancel{
    background-color:#9d9d9d; 
    border:none
}
.btn_cancel:hover{
    background-color:#9d9d9d; 
    border:none
}
th{
    text-align: start;
    padding: 5px 10px; 
    font-weight:normal; 
    border: #ebeef3 solid 1px
}
.largura_total{
    width: 100%;
}
select{
    width:100%;
    border: 1px solid #ced4da;
    padding:0.75rem 0.75rem;
    background-color:#fff;
    border-radius: 6px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
</style>
